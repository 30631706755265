$(document).ready(function(){
    $(document).on('click', '.validate-ticket', validateTicket);

    $(document).scannerDetection({
        timeBeforeScanTest: 200,
        endChar: [13],
        avgTimeByChar: 40,
        onComplete: function(barcode, qty){
            $('input[name=barcode]', '#form-scanner').val(barcode);
            $.ajax({
                url: $('#form-ticket-scanner').attr('action'), type: 'POST', dataType: 'json', data: $('#form-scanner').serialize(),
                success: function(rdata) {
                    addScannedResultHtml(rdata.tickets_scanner);
                }
            });
        },
        //onError: function(string){alert('Error ' + string);}
    });

    function validateTicket(evt)
    {
        evt.preventDefault();

        $.ajax({
            url: $('#form-ticket-scanner').attr('action'), type: 'POST', dataType: 'json', data: $('#form-ticket-scanner').serialize(),
            success: function(rdata) {
                addScannedResultHtml(rdata.tickets_scanner);
            }
        });

    }

    function addScannedResultHtml(result) {
        var aux_html = '';

        if (result.valid){
            aux_html = '<div class="alert alert-success">' +
                '<button type="button" class="close" data-dismiss="alert">×</button>' +
                '<b>'+ result.label +'</b><br />'+
                '<i>'+ result.ticket +'</i><br />'+
                '<br>'+
                '<b>'+ result.event +'</b><br />'+
                result.ticket_type +
                ' valid for <b>'+ result.visitors + '</b> visitor'+
            '</div>';
        }
        else{
            aux_html = '<div class="alert alert-danger">' +
                '<button type="button" class="close" data-dismiss="alert">×</button>' +
                '<b>'+ result.label +'</b>'+
                '</div>';
        }

        $('#scanned-result').html(aux_html);
    }
});
