/**
 * Created by Stijn Petry on 23/10/2017.
 */
$(document).ready(function(){
    $(document).on('click', '.post-add-fm-card', checkAndSaveFunMilesCard);
    $(document).on('click', '.post-add-fm-card-checkout', checkAndSaveFunMilesCardCheckout);


    function checkAndSaveFunMilesCard(e) {
        var card = $('input[name=card]').val();
        var pin = $('input[name=pin]').val();

        $.ajax({
            url: baseUrl + 'fun-miles/add-card', type: 'POST', dataType: 'json',
            data: {
                card: card,
                pin: pin
            },
            beforeSend: function() {
                $("#fm-login").hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    panelUpdated();
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                } else {
                    $("#fm-login").show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {

            }
        });
    }

    function checkAndSaveFunMilesCardCheckout(e) {
        var card = $('.card-field').val();
        var pin = $('.pin-field').val();

        $.ajax({
            url: baseUrl + 'fun-miles/add-card', type: 'POST', dataType: 'json',
            data: {
                card: card,
                pin: pin
            },
            beforeSend: function() {
                $("#fm-login").hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    $("#loader").hide();
                    $("#success-message-checkout").css("display","block");
                } else {
                    $("#fm-login").show();
                    $("#loader").hide();
                    $("#error-message-checkout").css("display","block");
                }
            },
            error: function () {

            }
        });
    }

    //********************************************************************************* //

    function panelUpdated(){
        $('#ticket-calculator').load(baseUrl + "calculator/panel");
    }

    //********************************************************************************* //
});
