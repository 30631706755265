$(document).ready(function(){

    $(document).on('click', '.open-modal', loadActionModals);
    $(document).on('change', '#month', getTransactions);

    function getTransactions(e) {
        var self = $(this);
        var preloader = $('#div-preloader');

        $.ajax({
            url: '/users/merchant/transactions',
            type: 'POST',
            dataType: 'html',
            data: {month : self.val(), merchant_id: self.data('merchant-id')},
            beforeSend: function(){
                preloader.show();
            },
            success: function (rdata) {
                $('.table-daily-sales-report tbody').html(rdata);
                loadActionModals(e);
            },
            complete: function () {
                preloader.hide();
            }
        });
    }

    function loadActionModals(e) {
        e.preventDefault();

        $('.open-modal').magnificPopup({
            removalDelay: 500,
            closeBtnInside: true,
            callbacks: {
                beforeOpen: function() {
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            midClick: true,
            type: 'ajax',
            fixedContentPos: true,
            overflowY: 'scroll'
        });
    }
});