$(document).ready(function(){
    $(document).on('click', '#post-add-free-ticket', saveFreeTicket);
    $(document).on('click', '#add-row', addRow);
    $(document).on('click', '#delete-row', deleteRow);
    $(document).on('submit', '.save-ajax', saveAjax);

    function saveFreeTicket(e) {
        e.preventDefault();

        var form = $('#form-free-ticket');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                $("#form-free-ticket").hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                } else {
                    $("#form-free-ticket").show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                $("#form-free-ticket").show();
                $("#loader").hide();
            }
        });
    }

    function saveAjax(e) {
        e.preventDefault();

        var form = $(this);
        var loader = $("#loader");
        var errorMessage = $("#error-message");

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                loader.show();
                errorMessage.hide();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                } else {
                    form.show();
                    loader.hide();
                    errorMessage.show();
                }
            },
            error: function () {
                form.show();
                loader.hide();
                errorMessage.show();
            }
        });
    }

    $('.free-ticket-modal').magnificPopup({
        removalDelay: 500,
        closeBtnInside: true,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true,
        type: 'ajax',
        fixedContentPos: true,
        overflowY: 'scroll'
    });

    function addRow(e) {
        e.preventDefault();

        var element = $(this);
        var table = $(element.data('table'));
        var rows = table.find('tbody tr');

        if (window.htmlRow === undefined){
            var row = $(rows[0]);
            window.htmlRow = row.parent().html();
        }

        table.find('tbody').append(window.htmlRow);
    }

    function deleteRow(e) {
        e.preventDefault();

        var element = $(this);
        var table = $(element.data('table'));
        var rows = table.find('tbody tr');

        if (rows.length === 1){
            window.htmlRow = rows.parent().html();
        }

        element.parentsUntil('tbody').remove();
    }
});