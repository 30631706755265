$(document).ready(function(){
    $(document).on('click', '#post-import-members-modal', confirmImportMembersModal);
    $(document).on('click', '#post-import-members', saveImportMembers);
    $(document).on('click', '#post-add-period', savePeriod);
    $(document).on('click', '#post-update-name', updateName);
    $(document).on('click', '#delete-card', deleteCard);
    $(document).on('click', '#post-edit-credits', editCredits);
    $(document).on('click', '#search-members', searchMembers);
    $(document).on('click', '#post-suspend-subscriptions', suspendSubscriptionsModal);
    $(document).on('click', '#post-confirm-suspend-subscriptions', suspendSubscriptions);
    $(document).on('click', '#post-change-subscription', changeSubscription);
    $(document).on('click', '.load-table-members', loadTableMembers);
    $(document).on('click', '.load-table-subscriptions', loadTableSubscriptions);
    $(document).on('click', '.add-renew-subscription', addRenew);
    $(document).on('click', '.modal-open', loadActionModals());

    function confirmImportMembersModal(e) {
        e.preventDefault();

        var form = $('#form-import-members');
        var data = new FormData(form[0]);

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: data,
            processData: false,
            cache: false,
            contentType: false,
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success) {
                    $.magnificPopup.open({
                        items: {
                            src: rdata.view,
                            type: 'inline'
                        }
                    });
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function saveImportMembers(e) {
        e.preventDefault();

        var form = $('#form-import-members');
        var data = new FormData(form[0]);

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: data,
            processData: false,
            cache: false,
            contentType: false,
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close();
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function savePeriod(e) {
        e.preventDefault();

        var form = $('#form-period');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function updateName(e) {
        e.preventDefault();

        var form = $('#form-update-name');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                    window.location.reload();
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function deleteCard(e){
        e.preventDefault();

        var form = $('#form-delete-card');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success == true) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                    window.location.reload();
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function editCredits(e){
        e.preventDefault();

        var form = $('#form-edit-credits');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function searchMembers(e){
        e.preventDefault();

        var form = $('#form-search-members');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader", '#tab-3').show();
                $("#error-message").hide();
            },
            success: function (rdata) {
                $("#loader", '#tab-3').hide();
                form.show();

                if(rdata.success) {
                    $('#content-search-members').html(rdata.view);
                } else {
                    $("#error-message").show();
                }

                loadActionModals();
            },
            error: function () {
                form.show();
                $("#loader", '#tab-3').hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function suspendSubscriptionsModal(e){
        e.preventDefault();

        var form = $('#form-suspend-subscriptions');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
                $("#error-message").hide();
            },
            success: function (rdata) {
                if(rdata.success) {
                    $.magnificPopup.open({
                        removalDelay: 500,
                        closeBtnInside: true,
                        callbacks: {
                            beforeOpen: function () {
                                this.st.mainClass = this.st.el.attr('data-effect');
                            }
                        },
                        items: {
                            src: rdata.view,
                            type: 'inline'
                        }
                    });

                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function suspendSubscriptions(e){
        e.preventDefault();

        var form = $('#form-confirm-suspend-subscription');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
                $("#error-message").hide();
            },
            success: function (rdata) {
                if(rdata.success) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened
                    window.location.reload();

                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function changeSubscription(e) {
        e.preventDefault();

        var form = $('#form-change-subscription');

        $.ajax({
            url: form.attr('action'), type: form.attr('method'), dataType: 'json',
            data: form.serialize(),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
                $("#error-message").hide();
            },
            success: function (rdata) {
                if(rdata.success) {
                    var magnificPopup = $.magnificPopup.instance; // save instance in magnificPopup variable
                    magnificPopup.close(); // Close popup that is currently opened

                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function addRenew(e) {
        e.preventDefault();

        var form = $('#form-add-renew'), target = $(e.target);

        if (target.data('payment-type') === undefined){
            target = $(e.currentTarget);
        }

        $('input[name=payment_type]').val(target.data('payment-type'));

        $.ajax({
            url: form.attr('action'), dataType: 'json', data: form.serialize(), type: form.attr('method'),
            beforeSend: function() {
                form.hide();
                $("#loader").show();
            },
            success: function (rdata) {
                if(rdata.success) {
                    window.location.reload();
                } else {
                    form.show();
                    $("#loader").hide();
                    $("#error-message").css("display","block");
                }
            },
            error: function () {
                form.show();
                $("#loader").hide();
                $("#error-message").css("display","block");
            }
        });
    }

    function loadTableSubscriptions(evn) {
        var target = $(evn.target), container = $('#table-subscriptions'), loader = $('#loader', '#tab-2');

        if (!target.data('loaded')){
            $.ajax({
                url: target.data('url'), dataType: 'html',
                beforeSend: function() {
                    loader.show();
                },
                success: function (rdata) {
                    $('h4', '#tab-2').removeClass('hide');
                    container.append(rdata);
                    target.data('loaded', 'true');
                    loader.hide();
                    loadActionModals();
                },
                error: function () {
                    loader.hide();
                }
            });
        }
    }

    function loadTableMembers(evn) {
        var target = $(evn.target), container = $('#table-members'), loader = $('#loader', '#tab-4');

        if (!target.data('loaded')){
            $.ajax({
                url: target.data('url'), dataType: 'html',
                beforeSend: function() {
                    loader.show();
                },
                success: function (rdata) {
                    container.html(rdata);
                    target.data('loaded', 'true');
                    loader.hide();
                    loadActionModals();
                },
                error: function () {
                    loader.hide();
                }
            });
        }
    }

    function loadActionModals() {
        $('.open-modal').magnificPopup({
            removalDelay: 500,
            closeBtnInside: true,
            callbacks: {
                beforeOpen: function() {
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            midClick: true,
            type: 'ajax',
            fixedContentPos: true,
            overflowY: 'scroll'
        });
    }
});