/**
 * Created by Stijn Petry on 12/09/2016.
 */
$(document).ready(function(){
    $(document).on('change', '.quantity', quantityChanged);
    $(document).on('click', '.get-calculator', getCalculator);
    $(document).on('click', '.method', methodChanged);
    $(document).on('click', '.currency', currencyChanged);
    $(document).on('click', '.checkout-tickets', checkoutTickets);
    $(document).on('load', '#ticket-calculator', panelUpdated);
    $(document).on('change', '.select-ticket-activity-time', activityTimeChanged);

    $(function () {
        if ($('#ticket-calculator').length > 0) {
            $('.ticket-types-activity-touchspin').hide();
            panelUpdated();
        }
    });

    /*
     Function to get a Change
     */
    function quantityChanged(evt) {
        evt.preventDefault();

        getTotals();
        $('#ticket-totals-view').show();
    }

    /*
     Function to get each item id, and quantity
     */
    function getTotals() {
        var jsonObj = [];
        var jsonStr = '';
        var totalPrice = 0.00;
        var totalFM = 0.00;
        var totalCount = parseInt(0);
        var items = $('#reservations_items');
        var total = $('#total-amount');
        var total_fm = $('#total-amount-fm');
        //var counter = $('#counter');
        $('.ticket-calculator').each(function (index, element) {
            var item = {};
            var t = $(this);

            item['db_id'] = t.find('.db_id').val();
            item['quantity'] = t.find('.quantity').val();
            item['price'] = t.find('.price-for-js').val();
            item['price_fm'] = t.find('.price-for-js-fm').val();
            item['subtotal'] = item['quantity'] * item['price'];
            item['subtotal_fm'] = item['quantity'] * item['price_fm'];
            t.find('.quantity-read-value').html(item['quantity']);
            totalPrice += item['subtotal'];
            totalFM += item['subtotal_fm'];
            totalCount = parseInt(totalCount) + parseInt(item['quantity']);

            // Only if id is not null
            if (item['db_id']) {
                jsonObj.push(item);
            }

        });
        jsonStr = JSON.stringify(jsonObj);
        items.val(jsonStr);
        total.html(totalPrice.toFixed(2));
        total_fm.html(totalFM.toFixed(0));

        $('#ticket-totals-view').show();
        if(parseInt(totalCount) > parseInt(0)) {
            $(".get-calculator").show();
            $(".dont-get-calculator").hide();
            $(".dont-get-calculator-step2").hide();
        } else {
            $(".get-calculator").hide();
            $(".dont-get-calculator").hide();
            $(".dont-get-calculator-step2").show();
        }
    }

    function getCalculator(evt) {
        evt.preventDefault();

        // get items
        getItems();

        $('#ticket-totals-view').hide();
        $('.quantity-input').hide();
        $('.bootstrap-touchspin').hide();
        $('.quantity-read-only').show();
    }

    function activityDateChanged(e) {
        var selector = $(this);

        console.log(e.format(0,"yyyy-mm-dd"));

        $('#ticket-calculator').html('');

        var date = e.format(0,"yyyy-mm-dd");
        var event_type = $('input[name=event_type]').val();
        var event_id = $('input[name=event]').val();

        $.ajax({
            url: baseUrl + 'calculator/select-activity-date', type: 'POST', dataType: 'json',
            data: {
                date: date,
                event_type: event_type,
                event_id: event_id,
            },
            success: function (rdata) {
                var price = 0.00;
                $('.please-select-date').hide();
                if(rdata.has_time_options) {
                    $('.activity-time').show();
                }
                $('.ticket-types-activity-touchspin').show();
                $('.quantity-read-only').hide();
                $('#ticket-totals-view').show();
                $(".get-calculator").hide();
                $(".dont-get-calculator").hide();
                $(".dont-get-calculator-step2").show();
                $(".container-extra-info").show();
                $('#total-amount').html(price.toFixed(2));
                $('.activity-time-slots').html(rdata.html);
                //save the calendar_event_id on the page
                var calendar_event_id = $('#calendar_event_id');
                calendar_event_id.val(rdata.id);
                $.each(rdata.availabilities, function (index, element) {
                    var touchspin = $('.ticket-selector-input-' + element.type_id);
                    var onlyXLeftContainer = $('.only-x-left-' + element.type_id);
                    var labelSold = '<span id="span-'+ element.type_id +'" class="label label-primary text-center" style="color:white;width:100% !important;font-size:18px;">SOLD OUT</span>';
                    var labelOnlyXLeft = '<div id="only-x-lift-span-'+ element.type_id +'" class="col-xs-12"><span class="only-x-left-text">Only '+ element.availability +' Tickets Left!</span></div>';
                    var parent = touchspin.parents().get(1);

                    if ($('#span-'+ element.type_id).length < 1){
                        $(parent).append(labelSold);
                    }

                    if (element.availability <= 0){
                        $(touchspin.parents().get(0)).hide();
                        $(touchspin).hide();
                        $('#span-'+ element.type_id).show();
                    }
                    else{
                        $('#span-'+ element.type_id).hide();
                        $(touchspin.parents().get(0)).show();
                        $(touchspin).show();
                        $('#only-x-lift-span-'+ element.type_id).remove();
                        if (element.availability <= 15 && element.availability >= 1){
                            onlyXLeftContainer.append(labelOnlyXLeft);
                            onlyXLeftContainer.show();
                            $('#only-x-lift-span-'+ element.type_id).show();
                        }
                    }
                    $(touchspin).val(0);
                    touchspin.trigger("touchspin.updatesettings", {max: element.availability});
                });
            },
            error: function () {
            }
        });
    }

    function activityTimeChanged() {
        var selector = $(this);

        var time = $('select[name=time]').val();

        //save the calendar_event_id on the page
        var calendar_event_id = $('#calendar_event_id');
        calendar_event_id.val(time);

        $.ajax({
            url: baseUrl + 'calculator/select-activity-time', type: 'POST', dataType: 'json',
            data: {
                id: time,
            },
            success: function (rdata) {
                var price = 0.00;
                $('.ticket-types-activity').show();
                $('.quantity-read-only').hide();
                $('#ticket-totals-view').show();
                $(".get-calculator").hide();
                $('#total-amount').html(price.toFixed(2));
                //save the calendar_event_id on the page
                var calendar_event_id = $('#calendar_event_id');
                calendar_event_id.val(rdata.id);
                $.each(rdata.availabilities, function (index, element) {
                    console.log('update availability for ' + element.type_id);
                    var touchspin = $('.ticket-selector-input-' + element.type_id);
                    var labelSold = '<span id="span-'+ element.type_id +'" class="label label-primary text-center" style="color:white;width:100% !important;font-size:18px;">SOLD OUT</span>';
                    var parent = touchspin.parents().get(1);

                    if ($('#span-'+ element.type_id).length < 1){
                        $(parent).append(labelSold);
                    }

                    if (element.availability <= 0){
                        $(touchspin.parents().get(0)).hide();
                        $(touchspin).hide();
                        $('#span-'+ element.type_id).show();
                    }
                    else{
                        $('#span-'+ element.type_id).hide();
                        $(touchspin.parents().get(0)).show();
                        $(touchspin).show();
                    }
                    $(touchspin).val(0);
                    touchspin.trigger("touchspin.updatesettings", {max: element.availability});
                });

                panelUpdated(rdata);
            },
            error: function () {
            }
        });
    }

    function methodChanged(evt) {
        evt.preventDefault();

        var paymentMethod = $(evt.target).closest('.method');
        var method = paymentMethod.data('id');

        $('input[name=method]').val(method);

        // get items
        getItems();
    }

    function currencyChanged(evt) {
        evt.preventDefault();

        var paymentCurrency = $(evt.target).closest('.currency');
        var currency = paymentCurrency.data('id');

        if(currency == 'eur') {
            $('input[name=method]').val(1);
        }

        if(currency == 'usd') {
            $('input[name=method]').val(31);
        }

        if(currency == 'ang') {
            $('input[name=method]').val(18);
        }

        if(currency == 'fm') {
            $('input[name=method]').val(14);
        }

        $('input[name=currency]').val(currency);

        // get items
        getItems();
    }

    function sendDataToController() {

        var items = $('input[name=reservations_items]').val();
        var method = $('input[name=method]').val();
        var currency = $('input[name=currency]').val();
        var event = $('input[name=event]').val();
        var event_type = $('input[name=event_type]').val();
        var calendar_event_id = $('input[name=calendar_event_id]').val();
        var iframe = $('input[name=iframe]').val();
        var mobile_app = $('input[name=mobile_app]').val();

        $.ajax({
            url: baseUrl + 'calculator/panel', type: 'POST', dataType: 'json',
            data: {
                items: items,
                method: method,
                currency: currency,
                event: event,
                event_type: event_type,
                calendar_event_id: calendar_event_id,
                iframe: iframe,
                mobile_app: mobile_app
            },
            beforeSend: function() {
                $("#ticket-calculator").hide();
                $(".container-loader").show();
                $(".container-extra-info").hide();
            },
            success: function (rdata) {
                $('.next-btn').show();
                panelUpdated(rdata);
            },
            error: function () {
            }
        });
    }

    /*
     Function to get each item id, and quantity
     */
    function getItems() {
        var jsonObj = [];
        var jsonStr = '';
        var items = $('#reservations_items');
        $('.ticket-calculator').each(function (index, element) {
            var item = {};
            var t = $(this);

            item['db_id'] = t.find('.db_id').val();
            item['quantity'] = t.find('.quantity').val();

            // Only if id is not null
            if (item['db_id']) {
                jsonObj.push(item);
            }

        });
        jsonStr = JSON.stringify(jsonObj);
        items.val(jsonStr);

        sendDataToController();
    }

    function checkoutTickets() {
        $.ajax({
            url: baseUrl + 'calculator/set-checkout', type: 'POST', dataType: 'json',
            success: function (rdata) {
                if (rdata.success){
                    $('.next-btn').show();
                    if(iFrame) {
                        window.open(rdata.redirect, '_parent');
                    } else {
                        window.location.href = rdata.redirect;
                    }
                    panelUpdated(rdata);

                } else {
                    var availableAlert = $('#available-error-message');
                    availableAlert.find('h5').text(rdata.available_error_message);
                    availableAlert.show();
                }
            },
            error: function () {
            }
        });
    }
    function checkCheckout() {
        $.ajax({
            url: baseUrl + 'calculator/check-checkout', type: 'POST', dataType: 'json',
            success: function (rdata) {
                $('#ticket-calculator').load(baseUrl + "calculator/panel");
            },
            error: function () {
            }
        });
    }

    //********************************************************************************* //

    function panelUpdated(data){
        if ($('#ticket-calculator-check').length > 0) {
            checkCheckout();
        } else {
            $('#ticket-calculator').load(baseUrl + "calculator/panel", function() {
                $(".container-loader").hide();
                $("#ticket-calculator").show();
            });
        }
    }

    //********************************************************************************* //


    $('#tickets-datepicker').datepicker({
        format: "yyyy-mm-dd",
        startDate: "today",
        weekStart:1,
        beforeShowDay: function (date) {
            var dt_ddmmyyyy = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            if (onlyThisDates.indexOf(dt_ddmmyyyy) != -1) {
                return {
                    //tooltip: 'This date is available',
                    classes: 'available'
                };
            } else {
                return false;
            }
        }
    }).on('changeDate', function(e) {
        activityDateChanged(e);
    });
});
