$(document).ready(function(){
    $(document).on('click', '.post-edit-calendar-event-availability', saveAvailabilities);

    function saveAvailabilities(e) {
        var activityId = $('#activity_id').val();
        $.ajax({
            url: baseUrl + 'users/my-activities/'+ activityId +'/calendar-event-availabilities',
            type: 'POST',
            dataType: 'json',
            data: $('#form-calendar-event-availability').serialize(),
            success: function (rdata) {
                if(rdata.success == true) window.location.reload();
            },
            error: function () {

            }
        });
    }

    $('.get-ticket-types').magnificPopup({
        removalDelay: 500,
        closeBtnInside: true,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true,
        type: 'ajax',
        fixedContentPos: true,
        overflowY: 'scroll'
    });

    $('.get-tickets-per-day').magnificPopup({
        removalDelay: 500,
        closeBtnInside: true,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true,
        type: 'ajax',
        fixedContentPos: true,
        overflowY: 'scroll'
    });

    $('.get-report').magnificPopup({
        removalDelay: 500,
        closeBtnInside: true,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true,
        type: 'ajax',
        fixedContentPos: true,
        overflowY: 'scroll'
    });
});