function showMessage(message) {
    $("[id=correctContainer]").each(function () {
        $(this).fadeIn(500);
        $(this).find('div').html("<span class='fa fa-check fa-fw'></span> " + message);
    });
    setTimeout(function () {
        $("[id=correctContainer]").each(function () {
            $(this).fadeOut(500);
        });
    }, 4000);
}

function showWaiting(message) {
    $("[id=waitingContainer]").each(function () {
        $(this).fadeIn(500);
        $(this).find('div').html("<img src='" + baseUrl + "images/loader.gif'> " + message);
    });
}

function showError(message) {
    $("[id=errorContainer]").each(function () {
        $(this).fadeIn(500);
        $(this).find('div').html(message);
    });
    setTimeout(function () {
        $("[id=errorContainer]").each(function () {
            $(this).fadeOut(500);
        });
    }, 4000);
}

function processErrors(errors) {
    var message = "";
    try {
        $.each(errors, function (key, value) {
            $.each(value, function (key2, value2) {
                message += "<span class='glyphicon glyphicon-remove'></span> " + value2 + "</br>";
            });
        });
    } catch (err) {
        return message = "<span class='glyphicon glyphicon-remove'></span> " + errors + "</br>";
    }
    return message;
}
